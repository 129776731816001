<template>
    <div class="availability__container">
        <div class="availability__heading">
            <h4 class="availability__heading-title">{{ $t('Availability') }}</h4>
        </div>

        <div class="availability__actions">
            <div
                class="availability__pagination-week-container"
            >
                <div class="availability__pagination-week">
                    <NebulaIconButton
                        v-if="!isMobile"
                        class="availability__pagination-week-arrow availability__pagination-week-arrow--previous"
                        icon="arrow-left-circle"
                        type="flat"
                        size="l"
                        @click="backWeek"
                        :aria-label="$t('previous-week', { ns: 'employeeprofile' })"
                        :customStyles="customStylesArrowWeekButton"
                    />
                    <div class="availability__pagination-week-date">
                        {{ $t('week-of', { ns: 'employeeprofile' }) }} {{ this.startOfWeekDate }}
                    </div>
                    <NebulaIconButton
                        v-if="!isMobile"
                        class="availability__pagination-week-arrow availability__pagination-week-arrow--next"
                        icon="arrow-right-circle"
                        type="flat"
                        size="l"
                        @click="forwardWeek"
                        :aria-label="$t('next-week', { ns: 'employeeprofile' })"
                        :customStyles="customStylesArrowWeekButton"
                    />
                </div>

                <NebulaDatepicker
                    class="availability__datepicker"
                    :calendar-button="true"
                    :show-calendar-on-button-click="true"
                    fixed-position="bottom-right"
                    @input="dateSelected"
                >
                    <template #calendarBtn>
                        <span v-if="!isMobile" class="vdp-datepicker__calendar-button-text">
                            {{ $t('view-calendar', { ns: 'profile' }) }}
                        </span>

                        <NebulaIcon
                            symbol-id="calendar"
                            :size="isMobile ? 'm' : 's'"
                            class="vdp-datepicker__calendar-button-icon"
                        />
                    </template>
                </NebulaDatepicker>
            </div>

            <div v-if="isMobile" class="availability__pagination-day-container">
                <div class="availability__pagination-day">
                    <NebulaButton
                        class="availability__pagination-day-arrow availability__pagination-day-arrow--previous"
                        :aria-label="$t('previous-day', { ns: 'employeeprofile' })"
                        :text="$t('previous-day', { ns: 'employeeprofile' })"
                        icon-left="arrow-left-circle"
                        type="flat"
                        size="s"
                        :disabled="currentDayIndex === 0"
                        @click="moveLeft"
                        :customStyles="customStylesPaginationDay"
                    />

                    <NebulaButton
                        class="availability__pagination-day-arrow availability__pagination-day-arrow--next"
                        :aria-label="$t('next-day', { ns: 'employeeprofile' })"
                        :text="$t('next-day', { ns: 'employeeprofile' })"
                        icon-right="arrow-right-circle"
                        type="flat"
                        size="s"
                        :disabled="currentDayIndex === this.daysOfWeek.length - this.visibleCount"
                        @click="moveRight"
                        :customStyles="customStylesPaginationDay"
                    />
                </div>
            </div>
        </div>

        <div class="availability__schedule">
            <div class="availability__schedule-col" v-if="showColumn && !isMobile">
                <span class="availability__schedule-day">
                    <span class="nebula-screenreader-only">{{ $t('time-of-day', { ns: 'employeeprofile' }) }}</span>
                </span>
                <div class="availability__schedule-block">
                    <div
                        v-for="time in timeOfDay"
                        :key="time"
                        class="availability__schedule-wrapper"
                    >
                        <span class="availability__schedule-button availability__schedule-button--icon">
                            <NebulaIcon
                                class="availability__schedule-button-svg"
                                :symbol-id="time"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div class="availability__schedule-col" v-for="day in visibleDays" :key="day.getTime()">
                <div class="availability__schedule-day">
                    <div class="availability__schedule-day--weekday">{{ getWeekDay(day) }}</div>
                    <div class="availability__schedule-day--date">{{ formatDate(day.getTime() / 1000, 'dateonly') }}</div>
                </div>

                <div class="availability__schedule-block">
                    <div class="availability__schedule-wrapper" v-for="(option, index) in timeOptions"
                         :key="option.text"
                    >
                        <button
                            class="availability__schedule-button"
                            :class="getButtonClass(day, index)"
                            @click="selectedTime"
                            :id="`${day}_${index}`"
                            :ref="`${day}_${index}`"
                        >
                            <span class="nebula-screenreader-only">{{ day }}</span>
                            <span class="availability__schedule-button-text"
                                  v-if="index === 0 || index === timeOptions.length -1"
                            >
                                {{ option.text.split('\t\n')[0] }}
                            </span>

                            <span class="availability__schedule-button-text" v-else>
                                <span
                                    class="availability__schedule-button-text--time"
                                >{{ option.text.split('\t\n')[0]
                                    }}</span>
                                <span
                                    class="availability__schedule-button-text--day"
                                >{{ option.text.split('\t\n')[1]
                                    }}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    NebulaButton,
    NebulaDatepicker,
    NebulaIcon,
    NebulaIconButton,
} from '@discoveryedu/nebula-components';
import breakpoints from '@/mixins/breakpoints';
import formatDate from '@/mixins/formatDate';
import createPayload from '@/mixins/data/createPayload';
// import getToken from '@/utils/getToken';

export default {
    name: 'Availability',
    components: {
        NebulaButton,
        NebulaDatepicker,
        NebulaIcon,
        NebulaIconButton,
    },
    model: {
        prop: 'saveButtonDisabled',
        event: 'saveButtonDisabledChange',
    },
    props: {
        showColumn: {
            type: Boolean,
            default: true,
        },
        showErrors: {
            type: Boolean,
            default: false,
        },
        availability: {
            type: Array,
        },
        employeeId: {
            type: String,
        },
    },
    data() {
        return {
            selectedTimeSlots: [],
            selectedTimes: [],
            selectedTimezone: '',
            buttonAvailable: 'availability__schedule-button--available',
            currentDayIndex: 0,
            customStylesPaginationDay: {
                buttonBackground: 'var(--nebula-color-platform-white)',
                buttonBackgroundHover: 'var(--nebula-color-platform-interactive-200)',
                buttonBackgroundActive: 'var(--nebula-color-platform-interactive-200)',
                buttonTextColor: 'var(--nebula-color-platform-neutral-900)',
                buttonTextColorActive: 'var(--nebula-color-platform-neutral-900)',
            },
            customStylesArrowWeekButton: {
                iconButtonBackgroundHover: 'var(--nebula-toast-button-background-hover)',
                iconButtonBackgroundActive: 'var(--nebula-toast-button-background-active)',
                iconButtonBorderRadius: 'var(--nebula-toast-button-border-radius)',
                iconButtonFill: 'var(--nebula-toast-button-fill)',
                iconButtonFillHover: 'var(--nebula-toast-button-fill-hover)',
                iconButtonFillActive: 'var(--nebula-toast-button-fill-active)',
            },
            timeSlots: [
                {
                    startHour: 6,
                    startMin: 0,
                    endHour: 8,
                    endMin: 0,
                },
                {
                    startHour: 8,
                    startMin: 0,
                    endHour: 10,
                    endMin: 0,
                },
                {
                    startHour: 10,
                    startMin: 0,
                    endHour: 12,
                    endMin: 0,
                },
                {
                    startHour: 12,
                    startMin: 0,
                    endHour: 14,
                    endMin: 0,
                },
                {
                    startHour: 14,
                    startMin: 0,
                    endHour: 17,
                    endMin: 0,
                },
                {
                    startHour: 17,
                    startMin: 0,
                    endHour: 20,
                    endMin: 0,
                },
            ],
            timeOfDay: [
                'time-early-morning',
                'time-late-morning',
                'time-morning',
                'time-afternoon',
                'time-late-afternoon',
                'time-night',
            ],
            selectedDate: new Date(new Date().toDateString()),
        };
    },
    mixins: [
        breakpoints,
        formatDate,
        createPayload,
    ],
    computed: {
        viewOnly() {
            return Boolean(this.employeeId);
        },
        startOfWeekDate() {
            return this.formatDate(this.getFirstDayOfWeek(this.selectedDate).getTime() / 1000, 'daylong');
        },
        visibleDays() {
            const days = [];
            let i = 0;
            let curDate = this.getFirstDayOfWeek(this.selectedDate);
            if (this.isMobile) {
                curDate = new Date(curDate.setDate(curDate.getDate() + this.currentDayIndex));
            }
            days.push(new Date(curDate));
            for (i = 1; i < this.visibleCount; i += 1) {
                curDate.setDate(curDate.getDate() + 1);
                days.push(new Date(curDate));
            }
            return days;
        },
        viewableTimeSlots() {
            if (this.employeeId) {
                return this.$store.state.page.time_slots;
            }
            return this.$store.state.user.timeSlots;
        },
        startSchedule() {
            const curStart = this.getFirstDayOfWeek(this.selectedDate);
            return curStart.getTime() / 1000;
        },
        endSchedule() {
            let curEnd = this.getFirstDayOfWeek(this.selectedDate);
            curEnd = curEnd.setDate(curEnd.getDate() + 6) / 1000;
            return curEnd;
        },
        visibleCount() {
            return this.isMobile ? 3 : 5;
        },
        timeOptions() {
            return [
                { text: this.$t('early-morning', { ns: 'employeeprofile' }) },
                { text: this.$t('morning', { ns: 'employeeprofile' }) },
                { text: this.$t('late-morning', { ns: 'employeeprofile' }) },
                { text: this.$t('afternoon', { ns: 'employeeprofile' }) },
                { text: this.$t('late-afternoon', { ns: 'employeeprofile' }) },
                { text: this.$t('evening', { ns: 'employeeprofile' }) },
            ];
        },
        daysOfWeek() {
            return [
                this.$t('monday', { ns: 'employeeprofile' }),
                this.$t('tuesday', { ns: 'employeeprofile' }),
                this.$t('wednesday', { ns: 'employeeprofile' }),
                this.$t('thursday', { ns: 'employeeprofile' }),
                this.$t('friday', { ns: 'employeeprofile' }),
            ];
        },
        timezoneOptions() {
            return [
                { value: 'America/New_York', text: this.$t('timezone-est', { ns: 'dropdown' }) },
                { value: 'America/Chicago', text: this.$t('timezone-cst', { ns: 'dropdown' }) },
                { value: 'America/Denver', text: this.$t('timezone-mst', { ns: 'dropdown' }) },
                { value: 'America/Los_Angeles', text: this.$t('timezone-pst', { ns: 'dropdown' }) },
            ];
        },
    },
    methods: {
        dateSelected(newDate) {
            this.selectedDate = newDate;
            // eslint-disable-next-line
            this.$nextTick(function() {
                this.loadSelectedTimes();
            });
        },
        getFirstDayOfWeek(date) {
            const iDayOfWeek = date.getDay();
            const iDifference = date.getDate() - iDayOfWeek + (iDayOfWeek === 0 ? -6 : 1);

            return new Date(date.setDate(iDifference));
        },
        getButtonClass(day, index) {
            const info = this.timeOptions[index].text.split('\t\n')[1];
            const className = [];

            if (!info) {
                // We haven't changed the localization yet, just use the actual text
                // since it is the same
                className.push(`availability__schedule-button--${this.timeOptions[index].text}`);
            }
            className.push(`availability__schedule-button--${info.toLowerCase().replace(/\s+/g, '-')}`);

            if (this.viewOnly) {
                className.push('availability__schedule-button--view-only');
            }

            return className;
        },
        findTimeSlotId(start) {
            const slot = this.selectedTimeSlots.find((item) => item.start === start);
            return slot.timeSlotId;
        },
        async selectedTime(event) {
            if (this.viewOnly) {
                return;
            }
            // Build our payload
            const selData = event.currentTarget.id.split('_');
            const selDate = new Date(selData[0]);
            const availability = {
                ...this.timeSlots[selData[1]],
                ...{ dayOfWeek: selDate.getDay() - 1 },
            };

            if (this.selectedTimes.includes(event.currentTarget.id)) {
                event.currentTarget.classList.remove(this.buttonAvailable);
                this.selectedTimes = this.selectedTimes.filter((e) => e !== event.currentTarget.id);
                const postData = this.findTimeSlotId(selDate.setHours(availability.startHour) / 1000);
                const payload = await this.createPayload(postData);
                this.$store.dispatch('deleteTimeSlot', payload);
            } else {
                event.currentTarget.classList.add(this.buttonAvailable);
                this.selectedTimes.push(event.currentTarget.id);
                const postData = {
                    start: selDate.setHours(availability.startHour) / 1000,
                    end: selDate.setHours(availability.endHour) / 1000,
                };
                const payload = await this.createPayload(postData, this.$auth);
                this.$store.dispatch('createTimeSlot', payload);
            }
        },
        loadSelectedTimes() {
            this.selectedTimes.forEach((curTime) => {
                if (this.$refs[curTime] && this.$refs[curTime][0]) {
                    this.$refs[curTime][0].classList.add(this.buttonAvailable);
                }
            });
        },
        async backWeek() {
            this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - 7));
            await this.getActiveSlots(this.startSchedule, this.endSchedule);

            // eslint-disable-next-line
            this.$nextTick(function() {
                this.loadSelectedTimes();
            });
        },
        async forwardWeek() {
            this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + 7));
            await this.getActiveSlots(this.startSchedule, this.endSchedule);

            // eslint-disable-next-line
            this.$nextTick(function() {
                this.loadSelectedTimes();
            });
        },
        moveLeft() {
            if (this.currentDayIndex > 0) {
                this.currentDayIndex -= 1;
            }
            // eslint-disable-next-line
            this.$nextTick(function() {
                this.loadSelectedTimes();
            });
        },
        moveRight() {
            if (this.currentDayIndex < this.daysOfWeek.length - this.visibleCount) {
                this.currentDayIndex += 1;
            }
            // eslint-disable-next-line
            this.$nextTick(function() {
                this.loadSelectedTimes();
            });
        },
        async getActiveSlots(start, end) {
            const { employeeId } = this;

            const payload = await this.createPayload({
                start,
                end,
                employeeId,
            });

            await this.$store.dispatch('getTimeSlots', payload);
            this.viewableTimeSlots.forEach((curSlot) => {
                let curDate = new Date();
                curDate.setTime(curSlot.start * 1000);

                // Ensure our times have no minutes or seconds
                if (curDate.getSeconds() !== 0) {
                    curDate = new Date(curDate.setSeconds(0));
                }
                if (curDate.getMinutes() !== 0) {
                    curDate = new Date(curDate.setMinutes(0));
                }
                const newStart = curDate.getTime() / 1000;

                const curDateOnly = new Date(curDate.toDateString());
                const index = this.timeSlots.findIndex((item) => item.startHour === curDate.getHours());
                const id = `${curDateOnly}_${index}`;
                this.selectedTimeSlots.push({
                    start: newStart,
                    end: curSlot.end,
                    timeSlotId: curSlot.timeSlotId,
                });
                this.selectedTimes.push(id);
            });
        },
    },
    async mounted() {
        // default to local timezone on mount
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.selectedTimezone = localTimezone;

        const start = this.startSchedule;
        const end = this.endSchedule;

        await this.$nextTick;
        await this.getActiveSlots(start, end);
        this.loadSelectedTimes();
    },
};
</script>

<style lang='stylus'>

.availability {
    &__datepicker {
        .nebula-date-picker__input {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &__heading {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &-title {
            font-size: $nebula-font-size-card-title;
            margin: 0;
        }
    }

    &__timezone {
        align-items: center;
        display: flex;
        gap: $nebula-space-1x;

        &-label {
            color: $nebula-color-platform-neutral-900;
            flex: 1 0 auto;
            font-weight: 500;
            margin-bottom: 0;

            .nebula-label {
                align-self: start;
                padding-top: $nebula-space-half;
            }
        }

        &-select {
            margin-bottom: 0;
        }
    }

    &__schedule {
        display: flex;
        gap: $nebula-space-1x;
        height: 100%;
        margin-top: $nebula-space-4x;
        overflow-x: auto;
        position: relative;

        &-day {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            justify-content: center;
            margin-bottom: $nebula-space-4x;
            min-height: $nebula-space-5x;
            text-align: center;
            width: 100%;

            &--weekday {
                font-size: $nebula-font-size-body-1;
            }

            &--date {
                font-size: $nebula-font-size-body-2;
                font-weight: 600;
            }
        }

        &-col {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        &-block {
            display: flex;
            flex-direction: column;
            gap: $nebula-space-2x;
            height: 100%;
        }

        &-wrapper {
            display: flex;
            height: 100%;
            min-height: $nebula-space-6x;
        }

        &-button {
            align-items: center;
            background-color: $nebula-color-platform-neutral-100;
            border-radius: $nebula-border-radius-small;
            border: none;
            box-shadow: none;
            color: $nebula-color-platform-neutral-900
            display: flex;
            font-weight: 600;
            justify-content: center;
            line-height: $nebula-font-line-height-heading;
            transition: all $nebula-transition-default;
            width: 100%;

            &:hover {
                background-color: $nebula-color-platform-interactive-200;
                color: $nebula-color-platform-interactive-900
            }

            &--available {
                background-color: $nebula-color-platform-interactive-200;
                color: $nebula-color-platform-interactive-900

                &:hover {
                    background-color: $nebula-color-platform-interactive-300;
                }
            }

            &--disabled {
                background-color: $nebula-color-platform-neutral-300;
                color: $nebula-color-platform-neutral-600;
                cursor: not-allowed;

                &:hover {
                    background-color: $nebula-color-platform-neutral-300;
                    color: $nebula-color-platform-neutral-600;
                }
            }

            // for viewing profiles
            &--view-only {
                pointer-events: none;
            }

            &--icon {
                background-color: transparent;
                padding: 0 $nebula-space-1x 0 0;

                &:hover {
                    background-color: transparent;
                }
            }

            &-text {
                &--time {
                    display: block;
                }

                &--day {
                    font-size: $nebula-font-size-caption
                    font-weight: 600;
                }
            }

            &-svg {
                fill: $nebula-color-platform-interface-700;
            }
        }
    }

    &__pagination {
        &-week {
            align-items: center;
            display: flex;

            &-container {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }

            &-date {
                font-size: $nebula-font-size-body-1;

                @media (min-width: $nebula-breakpoints-tablet-landscape) {
                    font-weight: 700;
                    margin: 0 $nebula-space-5;
                }
            }

            &-arrow {
                &--previous {
                    padding-left: 0;
                }

                .nebula-icon {
                    height: $nebula-space-3x;
                    width: $nebula-space-3x;
                }
            }
        }
    }

    &__actions {
        margin-top: $nebula-space-2x;
    }

    &__pagination {
        &-day {
            align-items: center;
            display: flex;
            justify-content: space-between;

            &-arrow {
                font-size: $nebula-font-size-caption;

                &--previous {
                    margin-left: -($nebula-space-1x);
                }

                &--next {
                    margin-right: -($nebula-space-1x);
                }
            }
        }

        &-week {
            &-date {
                @media (min-width: $nebula-breakpoints-tablet-landscape) {
                    margin: 0 $nebula-space-3x;
                }
            }
        }
    }
}

/* Datepicker */
.vdp-datepicker {
    --nebula-date-picker-calendar-border: none;
    --nebula-date-picker-caret-background-color: $nebula-color-platform-interface-900;
    --nebula-date-picker-caret-background-color-hover: $nebula-color-platform-interface-1000;
    --nebula-date-picker-calendar-today-background-color: $nebula-color-platform-interface-300;
    --nebula-date-picker-calendar-date-background-hover: $nebula-color-platform-interactive-200;
    --nebula-date-picker-calendar-date-background-selected: $nebula-color-platform-interactive-800;
    --nebula-date-picker-calendar-date-background-selected-hover: $nebula-color-platform-interactive-400;
    --nebula-date-picker-calendar-date-color-selected: $nebula-color-platform-white;

    &__calendar {
        box-shadow: $nebula-shadow-300;
        margin-top: $nebula-space-1x;

        @media (min-width: $nebula-breakpoints-tablet-landscape) {
            margin-top: $nebula-space-2x;
        }

        &-button {
            align-items: center;
            background-color: transparent;
            border-radius: $nebula-border-radius-small;
            color: $nebula-color-interactive-blue-300;
            cursor: pointer;
            display: flex;
            flex-shrink: 0;
            font-size: $nebula-font-size-body-2;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            margin-left: auto;
            padding: $nebula-space-1x $nebula-space-2x;
            text-decoration: none;
            transition: all $nebula-transition-default;
            width: max-content;

            &:focus,
            &:hover {
                background-color: $nebula-color-platform-interactive-200;
                color: $nebula-color-interactive-blue-300;

                .vdp-datepicker__calendar-button-icon {
                    fill: $nebula-color-interactive-blue-300;
                }
            }

            &:active {
                background-color: $nebula-color-platform-interactive-400;
                color: $nebula-color-interactive-blue-400;

                .vdp-datepicker__calendar-button-icon {
                    fill: $nebula-color-interactive-blue-400;
                }
            }

            &-text {
                margin-inline-end: $nebula-space-1x;
                vertical-align: middle;
            }

            &-icon {
                fill: $nebula-color-interactive-blue-300;
                vertical-align: middle;
            }
        }
    }
}

</style>
